<template>
  <section class="view-claim-auth__login flow">
    <ui-banner
      :description="error"
      :intent="$pepper.Intent.DANGER"
      :title="$t('user-portal.poap_claim_error_geolocation')"
      v-if="hasError"
    />

    <p 
      class="view-claim-auth__label"
      v-html="$t('user-portal.user_claim_poap_geolocation_explanations')"
    ></p>

    <actions-button
      appearance="primary"
      class="fill"
      :disabled="loading"
      :loading="loading"
      @click="onRequest"
    >{{ $t('user-portal.user_claim_poap_request_geolocation') }}</actions-button>
  </section>
</template>

<script>
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'PoapRequestGeolocation',

  components: {
    UiBanner
  },

  inject: [
    '$embed',
    '$poap',
  ],

  data() {
    return {
      error: null,
      loading: false,
      lifetime: 60 * 1000 // 1 Mins
    }
  },

  computed: {
    hasError() {
      return !this.$basil.isNil(this.error)
    }
  },

  methods: {
    onErrorGeolocation(e) {
      $console.error('Geolocation Error', e)
      this.error = this.$t('user-portal.poap_claim_geolocation_failed')
      this.loading = false
    },

    onHandleGeolocation({ coords, timestamp }) {
      this.coordinates = { 
        lat: this.$basil.get(coords, 'latitude'), 
        lng: this.$basil.get(coords, 'longitude')
      }
      this.geolocationValidated = true

      // localStorage.setItem(
      //   `poap_coordinates_${this.$basil.get(this.$embed, 'embed.id')}`,
      //   JSON.stringify({
      //     coords: {
      //       latitude: this.$basil.get(coords, 'latitude'), 
      //       longitude: this.$basil.get(coords, 'longitude'), 
      //     },
      //     timestamp: timestamp
      //   })
      // )

      this.$emit('confirm', this.coordinates)
      this.loading = false
    },

    onRequest() {
      this.error = null

      if(navigator.geolocation) {
        this.loading = true
        navigator.geolocation.getCurrentPosition(
          this.onHandleGeolocation,
          this.onErrorGeolocation
        )
      } else {
        this.error = this.$t('user-portal.poap_claim_geolocation_denied')
      }
    },

    onReset() {
      this.loading = true

      // Uncomment to re-add the coordinates in local storage
      // let previous = localStorage.getItem(`poap_coordinates_${this.$basil.get(this.$embed, 'embed.id')}`)

      // if(previous) {
      //   previous = JSON.parse(previous)

      //   if(previous.timestamp + this.lifetime > Date.now()) {
      //     this.onHandleGeolocation(previous)
      //   } else {
      //     localStorage.removeItem(`poap_coordinates_${this.$basil.get(this.$embed, 'embed.id')}`)
      //     this.loading = false
      //   }
      // } else {
        this.loading = false
      // }
    },
  },

  mounted() {
    this.onReset()
  },
}
</script>