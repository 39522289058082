export default {
  inject: [
    '$poap'
  ],

  data() {
    return {
      errorPrefix: 'poaps_claim_error'
    }
  },

  computed: {
    geolocationRequired() {
      return this.poapLocations.reduce((carry, location) => {
        if (location.geolocationRequired) {
          carry = true
        }
        return carry
      }, false)
    },

    loginRoute() {
      return { name: 'sayl-user-portal.claim.poaps.login' }
    },

    poapLocations() {
      return this.$poap.poapCollection ? this.$poap.poapCollection.poapLocations.map((location) => {
        return {
          asset: this.$basil.get(location, 'assetUrl', null),
          description: this.$basil.get(location, 'description', null),
          geolocationRequired: this.$basil.get(location, 'validateGeolocation', false),
          incentive: this.$basil.get(location, 'incentive', null),
          name: this.$basil.get(location, 'name', null),
          quantity: this.$poap.poapCollection.poaps.find(poap => poap.location_id === location.id).quantity
        }
      }) : []
    },
    
    signupRoute() {
      return { name: 'sayl-user-portal.claim.poaps.signup' }
    },

    confirmRoute() {
      return { name: 'sayl-user-portal.claim.poaps.confirm' }
    },
  },

  methods: {
    async view(payload) {
      return await this.$poap.viewCollection(payload)
    },

    async claim(payload) {
      return await this.$poap.claimCollection(payload)
    }
  }
}