<template>
  <div 
    class="view-claim-auth" 
    @keyup="onKeyUp">
    <div 
      class="view-claim-auth__body" 
      v-if="!done">
      <!-- Header -->
      <header class="view-claim-auth__header">
        <h1 class="view-claim-auth__title">{{ $t('user-portal.claim_poaps_title') }}</h1>
        <p class="view-claim-auth__descr">{{ $t('user-portal.claim_poaps_description') }}</p>
      </header>

      <!-- Error -->
      <section 
        class="view-claim-auth__login flow" 
        v-if="hasError">
        <ui-banner
          :description="claimError.description"
          :title="claimError.title"
          :intent="$pepper.Intent.DANGER"
        />
      </section>

      <!-- Error -->
      <section 
        class="view-claim-auth__login flow" 
        v-if="hasError422">
        <ui-banner
          :description="errors422"
          :title="$t('user-portal.poaps_claim_error_422')"
          :intent="$pepper.Intent.DANGER"
        />
      </section>

      <!-- Geolocation request -->
      <poap-request-geolocation 
        @confirm="onGeolocationConfirm"
        v-if="!displayConfirm"
      />

      <!-- Confirm button -->
      <section 
        class="view-claim-auth__login flow"
        v-if="displayConfirm">
        <p class="view-claim-auth__label">{{ $t('user-portal.user_claim_poaps_explanations') }}</p>

        <actions-button
          appearance="primary"
          class="fill"
          :disabled="loading"
          :loading="loading"
          @click="onConfirm"
        >{{ $t('user-portal.action_claim_poaps') }}</actions-button>
      </section>
    </div>

    <!-- Success -->
    <div 
      class="view-claim-auth__body" 
      v-if="done">
      <ui-banner
        class="layout-claim__banner"
        :intent="$pepper.Intent.SUCCESS"
        :title="$t('user-portal.claim_poap_success')">
        <template #description>
          <div class="layout-claim__banner-descr">
            <p v-html="$t('user-portal.claim_poap_success_description', { timer: timer })"></p>
          
            <actions-button
              class="layout-claim__banner-btn"
              icon-post="open"
              :href="{ name: 'sayl-user-portal.poaps' }"
              :size="$pepper.Size.S"
            >{{ $t('user-portal.claim_go_to_poaps') }}</actions-button>
          </div>
        </template>
      </ui-banner>
    </div>
  </div>
</template>

<script>
import MixinErrors from '@/helpers/errors.js'
import MixinClaimError from '@/modules/claim/helpers/errors.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'
import MixinPoapsClaim from '@/modules/claim/helpers/poaps-claim.js'

import PoapRequestGeolocation from '@/modules/claim/components/ui/poap-geolocation.vue'
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'ClaimLoginView',

  components: {
    PoapRequestGeolocation,
    UiBanner,
  },

  inject: [
    '$user',

    '$redirect'
  ],

  mixins: [
    MixinClaimError,
    MixinErrors,
    MixinRouteLeave,
    MixinPoapsClaim,
  ],

  data() {
    return {
      coordinates: null,
      debounceRedirect: null,
      done: false,
      errors: {},
      loading: false,
      status: null,
      timer: 10
    }
  },

  computed: {
    displayConfirm() {
      if(!this.geolocationRequired) {
        return true
      }

      return this.coordinates != null
    },

    errors422() {
      let messages = []
      
      if(this.hasError422) {
        Object.keys(this.errors).forEach(k => {
          this.errors[k].forEach(error => {
            messages.push(this.$t(`user-portal.poap_claim_error_${error}`))
          })
        })
      }

      return messages.length > 0 ? messages.join(', ') : null
    },

    hasError422() {
      return this.status === 422
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true
        this.errors = {}
        this.status = null

        let payload = {
          token: this.$route.params.token
        }

        if(this.geolocationRequired) {
          payload.item = { coordinates: this.coordinates } 
        }

        await this.claim(payload)
        await this.$user.init({})

        this.done = true
        this.onTimer()
      } catch(e) {
        this.handleErrors(e)
        this.handleClaimErrors(e)
        if(this.hasError422) {
          this.coordinates = null
        }
      } finally {
        this.loading = false
      }
    },

    onGeolocationConfirm(coordinates) {
      this.errors = {}
      this.status = null
      this.coordinates = coordinates
    },

    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onConfirm()
      }
    },

    onTimer() {
      const decrement = () => {
        this.timer = this.timer - 1
        if(this.timer > 0) {
          setTimeout(decrement, 1000)
        } else {
          this.$router.replace({ name: 'sayl-user-portal.poaps' }).catch(() => {})
        }
      }

      setTimeout(decrement, 1000)
    }
  },

  mounted() {
    this.claimError =  {
      status: null,
      title: null,
      description: null,
    }
    this.errors = {}
    this.status = null
    this.timer = 10
  }
}
</script>
