export default {
  data() {
    return {
      claimError: {
        status: null,
        title: null,
        description: null,
      },

      hasError: false,
    }
  },

  methods: {
    handleClaimErrors(e) {
      if(e.status == null) {
        e.status = 500
      }
      
      let prefix = 'nft_claim_error'

      if(this.isPeerTransfer) {
        prefix = 'nft_peer_transfer_error'
      }

      if(this.errorPrefix) {
        prefix = this.errorPrefix
      }

      this.claimError = {
        status: e.status,
        title: this.$t(`user-portal.${prefix}_${e.status}`),
        description: this.$t(`user-portal.${prefix}_${e.status}_description`),
      }

      if(e.status === 423) {
        let message = this.$basil.get(e, '_items.response.data.message', null)

        if(message) {
          this.claimError.description = this.$t(`user-portal.${prefix}_${message}`)
        }
      }

      if(e.status === 422) {
        this.hasError = false
      } else {
        this.hasError = true
      }

      $console.error(e)
    }
  }
}